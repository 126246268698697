import React from "react"
import user from "../../../user.json"
import QRCode from "react-qr-code"
import "../Testing-styles/mainteam.module.css"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useLocation } from "@reach/router"

const User = () => {
  const location = useLocation()
  const currentUrl = location.href
  const itemURL = currentUrl?.split("/").pop()
  const userData = user.user.filter(
    item => item.url === itemURL
  )
  // console.log("userData", userData)
  const quest = ans => {
    const value = currentUrl
    return (
      <>
        <div className=" border rounded gap-1 drop bg-light team-child">
          <SEO
            title={`D2i Technology - ${ans.name}`}
            description={`I am ${ans.name} with ${ans.experience_years} years of experience in ${ans.expertise_field}. I have expertise in ${ans.expertise}. Reach me at ${ans.email}`}
          />
          <div className="container box_1620">
            <div className="banner_inner">
              <div className="banner_content">
                <div className="media">
                  <img
                    className="img-responsive"
                    src={`${ans.imgsrc}`}
                    alt={`${ans.imgalt}`}
                  />

                  <div className="media-body ">
                    <div className="container">
                      {/* <h6>Hello Everybody, i am</h6> */}
                      <h3>{`${ans.name}`}</h3>
                      <h4>{`${ans.working_field}`}</h4>
                      {/* <p>{`I am ${ans.name} with ${ans.experience_years} years of experience in ${ans.experience_field}. I have expertise in ${ans.expertise}.`}</p> */}
                      <div className="single_about">
                        <p>{`${ans.about}`}</p>
                      </div>
                      <ul className="list basic_info txt-clr">
                        {ans.phone_number && (
                          <li>
                            <a
                              style={{ color: "rgba(255, 255, 255, 0.68)" }}
                              href={`tel:${ans.phone_number}`}
                            >
                              <i
                                style={{ color: "rgba(255, 255, 255, 0.68)" }}
                                className="bx bxs-phone"
                              ></i>
                              {`${ans.phone_number}`}
                            </a>
                          </li>
                        )}
                        {ans.email && (ans.email.endsWith("@d2itechnology.com") || ans.email.endsWith("@d2itechnology.in")) && (
                          <li>
                            <a
                              style={{ color: "rgba(255, 255, 255, 0.68)" }}
                              href={`mailto:${ans.email}`}
                            >
                              <i
                                style={{ color: "rgba(255, 255, 255, 0.68)" }}
                                className="bx bxs-envelope"
                              ></i>
                              {`${ans.email}`}
                            </a>
                          </li>
                        )}
                        {ans.linkedin_url && (
                          <li>
                            <a
                              style={{ color: "rgba(255, 255, 255, 0.68)" }}
                              href={`${ans.linkedin_url}`}
                              target="_blank"
                            >
                              <i
                                style={{ color: "rgba(255, 255, 255, 0.68)" }}
                                className="bx bxl-linkedin"
                              ></i>
                              {`${ans.linkedin_url}`}
                            </a>
                          </li>
                        )}
                        <li>
                          <a
                            style={{ color: "rgba(255, 255, 255, 0.68)" }}
                            href={`${ans.geoLocation}`}
                            target="_blank"
                          >
                            <i
                              style={{ color: "rgba(255, 255, 255, 0.68)" }}
                              className="bx bx-home"
                            ></i>{" "}
                            {`${ans.location}`}
                          </a>
                        </li>
                        <div
                          style={{
                            height: "auto",
                            maxWidth: 100,
                            width: "100%",
                          }}
                          className="qrcode"
                        >
                          <QRCode
                            size={256}
                            style={{
                              height: "auto",
                              maxWidth: "100%",
                              width: "100%",
                            }}
                            value={value}
                            viewBox={`0 0 256 256`}
                          />
                          <h6 className="profile txt-clr">Profile link</h6>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <Layout>
      <SEO
        title="Manual Testing Company in Delhi NCR, India, USA & UK"
        description="Manual Testing is one of the core services offered by D2i. D2i is one of the leading company in India specializing in Manual Testing."
      />
      <div>{userData.map(ans => quest(ans))}</div>
    </Layout>
  )
}

export default User
